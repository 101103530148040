import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/docs/src/components/layouts/code-block.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { Modal } from 'godspeed'
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      